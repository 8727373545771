



































import {TokenPriced} from '@/model/resource/TokenPriced'
import {InputTokenPricedSchema} from '@/schema/resource/TokenPriced/InputTokenPricedSchema'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class TokenPricedModal extends Vue {
  @Prop({type: TokenPriced, required: true}) token!: TokenPriced

  showModal: boolean = false
  schema = new InputTokenPricedSchema()
  open = false

  async persistContract() {
    await this.token.persistTokenPriced()
    this.$toast.success('system.success.persist')
    this.showModal = false
    this.$emit('persisted')
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}
