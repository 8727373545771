/**
 * Input Schema of Token Priced
 * @author Lopes
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import {DappCollection} from '@/model/collection/DappCollection'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {TokenPriced} from '@/model/resource/TokenPriced'
import {InputCheckbox} from '@simpli/vue-input'
import * as Component from '@simpli/vue-input'

export class InputTokenPricedSchema extends DefaultSchema {
  collectionDapp = new DappCollection().noPagination()

  readonly name = 'InputTokenPriced'

  readonly fieldSet: FieldSet<TokenPriced> = {
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'token',
        compressDimension: $.auth.global.dappImageDimension,
        crop: true,
        class: 'scoped__image-url w-full',
      },
    }),
    symbol: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
