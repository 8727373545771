/**
 * List Schema of Contract Nep 17
 * @author Lopes
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {TokenPriced} from '@/model/resource/TokenPriced'
import NetworkTag from '@/components/NetworkTag.vue'

export class ListTokenPricedSchema extends DefaultSchema {
  readonly name = 'ListTokenPriced'

  readonly fieldSet: FieldSet<TokenPriced> = {
    idTokenPricedPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    network: (schema): FieldComponent => ({
      is: NetworkTag,
      bind: {
        blockchainTagName: schema.model.blockchainVersion?.tagName,
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    decimals: (): FieldComponent => ({
      is: Component.Render,
    }),
    type: (): FieldComponent => ({
      is: Component.Render,
    }),
    hash: (): FieldComponent => ({
      is: Component.Render,
    }),
    symbol: (): FieldComponent => ({
      is: Component.Render,
    }),
    totalSupply: (): FieldComponent => ({
      is: Component.Render,
    }),
    isSwappable: (): FieldComponent => ({
      is: Component.Render,
    }),
    createdAt: (): FieldComponent => ({
      is: Component.Render,
    }),
    updatedAt: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
