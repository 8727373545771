/**
 * TokenPriced
 * @author Lopes
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Contract} from '@/model/resource/Contract'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

export class TokenPriced implements IResource {
  @ResponseSerialize(Contract)
  contract: Contract | null = null

  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion | null = null

  idTokenPricedPk: number | null = 0

  title: string | null = null
  imageUrl: string | null = null
  decimals: number | null = 0
  type: string | null = null
  hash: string | null = null
  symbol: string | null = null
  totalSupply: string | null = null
  order: number | null = 0
  isSwappable: boolean | null = null
  active: boolean | null = null
  createdAt: Date | null = null
  updatedAt: Date | null = null

  get $id() {
    return this.idTokenPricedPk || 0
  }

  get $tag() {
    return String(this.title)
  }

  /**
   * Increments the order of TokenPriced
   */
  async changeOrder() {
    return await Request.post(`/client/token-priced/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }

  /**
   * Update active of TokenPriced
   */
  async changeActive() {
    return await Request.post(`/client/token-priced/${this.$id}/active`, this)
      .name('changeActive')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of TokenPriced. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistTokenPriced() {
    return await Request.post(`/client/token-priced`, this)
      .name('persistTokenPriced')
      .asNumber()
      .getData()
  }
}
